import { Form, Input } from 'antd';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

const OtpField=({label, name, type, required=false, requireMsg, disable=false, size='large', length, onChange})=> {
    const isMdScreen = useMediaQuery({
        query: '(max-width: 768px)'
      })

      useEffect(()=> {
        const separtorline = document.getElementById("sperator-line");
        if(!separtorline) {
            var elt = document.getElementsByClassName("ant-otp-input");
            const node = document.createElement("span");
            const textnode = document.createTextNode("-");
            node.appendChild(textnode);
            elt[2].insertAdjacentHTML('afterend', "<hr id='sperator-line'  style='width: 20px;height:3px; background: #000; opacity:0.38; margin:0; border:none'/>")
            console.log("el")
        }
        
      }, [])
    return (
        <React.Fragment>
                <Form.Item
                    style={{width: "100%" }}
                    className='bg'
                    label={label ?? ''} 
                    name={name}
                    rules={[{ required: required, type: type, message: requireMsg ?? "Champ obligatoire" }]}
                >
                    <Input.OTP 
                        formatter={(str) => str.toUpperCase()} 
                        disabled={disable} 
                        size={size} 
                        length={length}
                        onChange={onChange}
                        variant={!isMdScreen ? "filled" : "outlined"}
                        />
                    
                </Form.Item>
                
        </React.Fragment>
    )
}

export default OtpField;