import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Progress } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";

const StepHeader = ({ step, goToPrevious }) => {
  return (
    <React.Fragment>
      <div className="steps-header">
        
          <div style={{display: 'flex', alignItems: 'center', justifyContent:'center', cursor:'pointer'}} onClick={goToPrevious}>
            <ArrowBackIosIcon className="text-back-link" fontSize="18px"/>
            <span className="text-back-link roboto-regular">Retour</span>
            {/* <span onClick={goToPrevious} className="retour">
              <p className="back-link" style={{ cursor: "pointer" }}>
                
              </p>
            </span> */}
          </div>

        <div className="progess-bar" id="counter">
          <span className="progess-bar-count">{step}/5</span>
          <Progress
            showInfo={false}
            strokeColor="#ED8A26"
            percent={20 * step}
          />
        </div>
        <div className="step-header-close">
          <NavLink to="/" style={{ color: "black" }}>
            <CloseOutlined style={{ fontSize: 18, fontWeight: "700" }} />
          </NavLink>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StepHeader;
