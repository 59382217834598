import { Col, DatePicker, Form } from "antd";
import React from "react";
//import { disabledNextDate } from "../../../../helpers/app_helper";

const DateField = ({ label, name,placeholder="AAAA/MM/JJ HH:mm:ss", onChange,  disable, format = "YYYY/MM/DD HH:mm:ss", showTime=false,  xs, md }) => {
  const _onChange = (value) => {
    console.log("_onChange : ", value?.target?.value);
    if (onChange && value) {
      onChange?.(value?.target?.value);
    }
  };
  return (
    <React.Fragment>
      <Form.Item
        label={label}
        name={name}
        rules={[{ required: true, message: "Champ obligatoire" }]}
      >
        <DatePicker
          placeholder={placeholder}
          showTime={showTime}
          style={{ width: "100%" }}
          size="large"
          format={format}
          disabled={disable}
          //contentEditable
          mask="_"
          onChange={_onChange}
        />

     {/*  <DatePicker
          onChange={_onChange}
          placeholder={placeholder}
          format="DD-MM-YYYY"
          mask="_"
          className="ant-input ant-input-lg css-dev-only-do-not-override-1qhpsh8 ant-input-outlined"
          disabled={disable}
        /> */}

      </Form.Item>
    </React.Fragment>
  );
};

export default DateField;
