import { NavLink } from "react-router-dom";
import logo from "../../../assets/LOGO-PAYME-(LONACI)-V3.png";
import logoOnline from "../../../assets/online_logo.png";
import "./header.scss";
import { Button, Nav, NavItem, Navbar, NavbarBrand } from "reactstrap";
import {
  HomeOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
  TrademarkCircleOutlined,
} from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { Drawer, Menu } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const isMdScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="header-navbar">
      <Navbar color="success" dark expand="md" className="navbar">
        <div className="header">
            <div className="nav-link">
              <a href="/"><img src={logo} alt="" className="header-logo" /></a>
              <a href="http://www.lonacionline.ci" target="_blank"><img src={logoOnline} alt="" className="header-logo" /></a>
            </div>
          

          {/* <div className="btn-faq">
            <a href="/#faq">
              <Button
                color="light"
                className="header-faq-btn"
                style={{ cursor: "pointer" }}
              >
                <QuestionCircleOutlined
                  style={{
                    width: "21px",
                    height: "21px",
                    paddingRight: "10px",
                    fontSize: "20px",
                  }}
                />
                FAQ
              </Button>
            </a>
          </div> */}
        </div>
      </Navbar>
      {isMdScreen && open && (
        <Drawer
          open={open}
          width={200}
          placement="left"
          onClose={onClose}
          closable={false}
        >
          <Menu
            style={{ width: "100%" }}
            mode="vertical"
            theme="light"
            items={[
              {
                key: "1",
                icon: <HomeOutlined />,
                label: "Accueil",
                onClick: () => {
                  navigate("/");
                  setOpen(false);
                },
              },
              {
                key: "1",
                icon: <TrademarkCircleOutlined />,
                label: "Resultat",
                onClick: () => {
                  window.open("https://lonacionline.ci/", "_blank");
                },
              },
            ]}
          />
        </Drawer>
      )}
    </div>
  );
};

export default Header;
