import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React, { useState } from "react";
import orange from "../../../assets/orange-money.png";
import wave from "../../../assets/wave-logo.png";
import moov from "../../../assets/moov-logo.png";
import mtn from "../../../assets/mtn-logo.png";
import "./stepModePaiement.scss";
import { Card } from "antd";

const Step3 = ({ title, data, sCallback }) => {
  const onValidate = (paymentMethod) => {
    if (sCallback) {
      sCallback(data, { operator: paymentMethod });
    }
  };

  return (
    <React.Fragment>
      <div className="step-oeprator-choice-block">
        <h2 style={{ marginTop: 0 }} className="step-operator-title">
          {title}
        </h2>
        <Card className="step-operator-card">
          <div className="container-paiement">
            <div
              className="paiement"
              onClick={() => onValidate("ORANGE")}
              style={{ cursor: "pointer" }}
            >
              <div className="step-operator-logo-text">
                <img className="img-om" src={orange} alt="" />
                <span style={{ cursor: "pointer" }} className="text-om">
                  Orange Money
                </span>
              </div>
              <ArrowForwardIosIcon className="arrow" />
            </div>
            <hr className="line-height" />
            <div
              className="paiement"
              onClick={() => onValidate("MTN")}
              style={{ cursor: "pointer" }}
            >
              <div className="step-operator-logo-text">
                <img className="img-om" src={mtn} alt="" />
                <span style={{ cursor: "pointer" }} className="text-om">
                  MTN Money
                </span>
              </div>
              <ArrowForwardIosIcon
                className="arrow"
                style={{ fontWeight: 700 }}
              />
            </div>
            <hr className="line-height" />
            <div
              className="paiement"
              onClick={() => onValidate("MOOV")}
              style={{ cursor: "pointer" }}
            >
              <div className="step-operator-logo-text">
                <img className="img-om" src={moov} alt="" />
                <span style={{ cursor: "pointer" }} className="text-om">
                  Moov Money
                </span>
              </div>
              <ArrowForwardIosIcon className="arrow" />
            </div>
            <hr className="line-height" />
            <div
              className="paiement"
              onClick={() => onValidate("WAVE")}
              style={{ cursor: "pointer" }}
            >
              <div className="step-operator-logo-text">
                <img className="img-om" src={wave} alt="" />
                <span style={{ cursor: "pointer" }} className="text-om">
                  Wave
                </span>
              </div>
              <ArrowForwardIosIcon className="arrow" />
            </div>
            <hr className="line-height" />
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Step3;
