import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import "../form-style.scss";
import { InfoCircleOutlined } from "@ant-design/icons";
import { StraightOutlined } from "@mui/icons-material";

const SimpleInputField = ({
  label,
  placeholder,
  name,
  type,
  required = false,
  requireMsg,
  disable = false,
  size = "large",
  iProps,
  suffix,
  isCapitalise = false,
}) => {
  //const [focus, setFocus] = useState(false);

  // useEffect(()=> {
  //   //document.querySelector(".ant-form-item-label").classList.add("form-item-on-focus");
  //   console.log()
  // }, [focus])
  return (
    <React.Fragment>
      <Form.Item
        style={{ width: "100%" }}
        label={label ?? ""}
        //className={focus ? 'form-item-on-focus' : ''}
        name={name}
        rules={[
          {
            required: required,
            type: type,
            message: "Champ obligatoire",
          },
        ]}
        tooltip={{
          title: required ? 'Champs obligatoire' : "",
          icon: <span style={{color: 'red'}}>{required ? '*' : ''}</span>,
        }}
      >
        <Input
          //onBlur={() => setFocus(false)}
          //onFocus={() => setFocus(true)}

          placeholder={placeholder ?? label}
          size={size}
          disabled={disable}
          suffix={suffix}
          {...iProps}
          onInput={(e) =>
            isCapitalise ? (e.target.value = e.target.value.toUpperCase()) : ""
          }
          
        />
      </Form.Item>
    </React.Fragment>
  );
};

export default SimpleInputField;
