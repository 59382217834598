import React from 'react'

import { Select } from 'antd'

export default function SelectWithFilter(props) {
    return (
        <>
            <Select
                showSearch
                bordered={true}
                
                size={props?.size ?? 'large'}
                filterOption={(input, option) => {
                        return `${option?.children}`?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                    }  
                }
                {...props}
            />
        </>
    );
}