import React from "react";
import "./home.page.scss";
import { Layout, Collapse } from "antd";
import {
  UpOutlined,
} from "@ant-design/icons";
import momOperators from "../../assets/momo-operateurs.png";

const { Content } = Layout;

const Home = () => {
  const items = [
    {
      key: "1",
      label: (
        <b className="faq-collapse-title">
          Comment puis-je vérifier si mon ticket de pari est gagnant ?
        </b>
      ),
      children: (
        <p className="items">
          Cliquez sur le bouton <strong>« Cliquez pour commencer »</strong> et saisissez les
          informations présentes sur le ticket dans la page de vérification.
          Vous Encaissez instantanément le statut du ticket.
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <b className="faq-collapse-title">
          Quels sont les modes de paiement disponibles pour encaisser mes gains?
        </b>
      ),
      children: (
        <p className="items">
          Encaissez vos gains sur:
          <ul style={{listStyle: "none"}}>
            <li >- votre compte Wave</li>
            <li>- les comptes mobiles money de Orange, MTN et Moov</li>
          </ul>
          De plus, en associant votre compte mobile money à votre compte de jeu sur notre site, vous recevrez votre argent
          dans les plus brefs délais et en toute sécurité.
        </p>
      ),
    },
    {
      key: "3",
      label: (
        <b className="faq-collapse-title">
          Existe-t-il des frais liés à l'encaissement du gain?
        </b>
      ),
      children: (
        <p className="items">
          Aucun frais n'est appliqué lors de l'encaissement de votre gain via
          PayMe By LONACI.
        </p>
      ),
    },
    {
      key: "4",
      label: (
        <b className="faq-collapse-title">
          Combien de temps faut-il pour recevoir mon gains?
        </b>
      ),
      children: (
        <p className="items">
          Encaissez instantanément votre gain une fois le ticket gagnant vérifié
          dans notre système, est déclaré gagnant et l'opération de retrait
          atteint son terme.
        </p>
      ),
    },
  ];

  return (
    <Layout>
      <div style={{maxHeight: '100vh', display:'flex', justifyContent: 'space-between', flexDirection:'column'}}>
        <div className="home-image">
          <div className="home-container">
            <div className="home-hero-text tracking-in-expand-fwd-top">
              <span className="verify hero-page-span-title">
                Encaissez votre gain <br />SANS VOUS DEPLACER
              </span>
              {/* <span className="verify">
                Encaissez votre gain instantanément <br /> sur votre compte Mobile
                Money <br /> SANS VOUS DEPLACER
              </span> */}
              {/* <span className="verify">Vérifiez votre gain et</span>
              <span className="verify"> Encaissez instantanément</span>
              <span className="verify"> votre argent</span> */}
            </div>
            <div style={{marginTop: 20}}>
              <img src={momOperators} className="operator-img"/>
            </div>

            {/* <a href="/paiement-ticket#retours">
              <button className="btn-verifier-ticket">
                {" "}
                Cliquez ici pour commencer
              </button>
            </a> */}
          </div>
        </div>
        <div className="content-accueil">
          <div className="title-content">
            <div className="title-content-items">
              <span className="title-36"> Comment ça marche ? </span>
              {/* <span className="style-title roboto-light">
                {" "}
                Encaisser vos gains instantanés sur votre compte mobile sans vous
                déplacer.
              </span> */}
            </div>
          </div>

          <div className="blocs-steps">
            <div className="step">
              <div className="home-step-title roboto-regular">
                <div className="step-chiffre-title">1</div>
                <span style={{ fontSize: "24px" }}> Vérifiez le statut de <br class="no-br" />votre ticket </span>
              </div>
              {/* <div className="step-texte roboto-light">
                <span>
                  {" "}
                  Vérifiez le statut de votre ticket en saisissant les références
                  présentes de votre ticket de pari
                </span>
              </div> */}
            </div>

            <div className="step-center-style">
              <div className="step step-w70">
                <div className="home-step-title">
                  <div className="step-chiffre-title">2</div>
                  <span>
                    Choisissez le mode <br class="no-br" />
                    de paiement
                  </span>
                </div>
                {/* <div className="step-texte step-center-texte-style roboto-light">
                  <span>
                    Choisissez le moyen par lequel vous souhaitez être payé et
                    vous recevrez un code de confirmation pour finaliser votre
                    opération <br className="no-br" />{" "}
                  </span>
                </div> */}
              </div>
            </div>

            <div className="step">
              <div className="home-step-title">
                <div className="step-chiffre-title">3</div>
                <span className="step-chiffre-title-2"> Encaissez votre gain </span>
              </div>
              {/* <div className="step-texte roboto-light">
                <span>
                  {" "}
                  Encaissez votre gain immédiatement sur votre compte Mobile Money
                </span>
              </div> */}
            </div>
          </div>
        
          <div className="btn-bottom-verify-ticket">
            <a href="/paiement-ticket#retours">
              <button className="btn-verifier-ticket ">
                {" "}
                Cliquez pour commencer
              </button>
            </a>
          </div>
        </div>
      </div>

      <Content className="content-accueil">
      
        <div id="faq">
          <div className="block-title-items">
            <span className="title-items">Questions fréquentes</span>
            <Collapse
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                <UpOutlined
                  rotate={isActive ? 0 : 180}
                  style={{ fontSize: 15 }}
                />
              )}
              style={{
                background: "#fef8eea8",
              }}
              items={items}
              bordered={false}
              defaultActiveKey={["1"]}
            />
          </div>
        </div>
      </Content>
    </Layout>
  );
};
export default Home;
