import React, { useState } from "react";
import "./stepConfirmation.scss";
import { Button, Card, Form, Input, Modal, Result, Alert, Progress } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { ProductService } from "../../../common/services";
import CustomAntdForm from "../../components/forms/custom-antd-form";
import _ from "lodash";
import Countdown from "react-countdown";
import OtpField from "../../components/forms/fields/OtpField";
import Btn from "../../components/Btn";
import codeConfirmationSendIcon from "../../../assets/icones/code-confirmation-send-icon.png";

const Step5 = ({ title, data, sCallback, eCallback }) => {
  console.log("data pris :::", data);
  const [form] = Form.useForm();
  const [alertErrors, setAlertErrors] = useState(null);
  const [isPaiementOk, setIsPaiementOk] = useState(false);
  const [isPaiementSubmitting, setIsPaiementSubmitting] = useState(false);
  const [isOTPSubmitting, setIsOTPSubmitting] = useState(false);
  const [countDowning, setCountDowning] = useState(true);
  const [countDowningProgessBar, setCountDowningProgessBar] = useState(0);
  const [state, setState] = useState({
    isOTPGenerating: false,
    isOtpError: false
  })

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setCountDowning(false);
      return null;
    } else {
      console.log("seconds :", seconds)
      //setCountDowningProgessBar(seconds/10)
      // Render a countdown
      //<span>{minutes}:{seconds}</span>
      return <Progress percent={(seconds * 100/60)} showInfo={false} strokeColor={"grey"} style={{width: '50%'}} />;
    }
  };

  const payTicket = () => {
    form.validateFields().then((values) => {
      console.log(" values : OTP :", values);
      const phoneNumber = _.replace(
        data?.ticketInfo?.phoneNumber,
        new RegExp("[\\s-]", "g"),
        ""
      );
      const payTicketRequest = {
        ticketNumber: data?.ticketInfo?.ticketNumber,
        productCode: data?.initForm?.productCode,
        phoneNumber: phoneNumber,
        operator: data?.ticketInfo?.operator,
        otp: values?.codeOTP,
        firstname: data?.ticketInfo?.firstname,
        lastname: data?.ticketInfo?.lastname
      };
      console.log("payTicketRequest :", data);

      setIsPaiementSubmitting(true);
      ProductService.payTicket(payTicketRequest)
        .then((response) => {
          if(response?.success && sCallback) {
            sCallback(null, response?.data)
          }
        })
        .catch((error) => {
          if (error?.success && _.startsWith(error?.message, "APP_ERROR_PAYMENT:")) {
            eCallback()
          }
          else setAlertErrors(error?.success && _.startsWith(error?.message, "APP_ERROR:") ? error?.message?.replace("APP_ERROR:", "") : "Une erreur est survenue pendant le traitement de votre requête, veuillez réessayer");
        
        }).finally(()=> setIsPaiementSubmitting(false));
    })
    
  };

  const resendOTP=()=> {
    //setCountDowning(true)
    const phoneNumber = _.replace(
      data?.ticketInfo?.phoneNumber,
      new RegExp("[\\s-]", "g"),
      ""
    );
    
    const generateOTPRequest = {
      productCode: data?.initForm?.productCode,
      phoneNumber: phoneNumber,
      ticketNumber: data?.ticketInfo?.ticketNumber
    };

    setState(state=> ({...state,isOTPGenerating: true, isOtpError: false }));
    ProductService.generateOTP(generateOTPRequest)
      .then((response) => {
        if(response?.success) {
          setCountDowning(true)
        }
      })
      .catch((error) => {
        console.log(error);
        setState(state=> ({...state, isOtpError: true}))
      })
      .finally(()=> setState(state=> ({...state, isOTPGenerating: false})));
  }

  return (
    <React.Fragment>
      <div className="step-otp-confirm-block">
          <h2 className="step-otp-title">{title}</h2>
          <Card className="step-otp-card">
            <div className="send-code-otp">
              <div className="sendMessage">
                {/* <MailOutlined /> */}
                <img src={codeConfirmationSendIcon} height={50}/>
                <span className="text">
                  Veuillez saisir le code de confirmation envoyé au{" "}
                  <span className="number">
                    {data?.ticketInfo?.phoneNumber}
                  </span>
                </span>
              </div>
              <CustomAntdForm form={form}>
                <OtpField name={"codeOTP"} />

                {alertErrors && (
                  <div
                    className="animated"
                    style={{ marginBottom: 10, textAlign: "center" }}
                  >
                    <Alert message={`${alertErrors}`} type="error" showIcon />
                  </div>
                )}
                <div style={{height: 30, width: "100%"}}></div>
                <Btn
                  title={isPaiementSubmitting? "Traitement en cours ..." : "Confirmer"}
                  onClick={payTicket}
                  loading={isPaiementSubmitting}
                />
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <div className="otp codeOTP">
                    <span >
                      Vous n'avez pas reçu de code OTP ?{" "}
                    </span>
                    {!countDowning && <Button type="link" loading={state?.isOTPGenerating} className="codeRenvoyer" onClick={()=> resendOTP() }>Renvoyer</Button>}
                  </div>
                    
                  {state?.isOtpError && (<span style={{color: "#E81F2BE0"}}>Une erreur est survenue ee</span>) }
                  {(!state?.isOtpError && countDowning) && <Countdown
                                    key={Date.now().toString()}
                                    date={Date.now() + 60000}
                                    renderer={renderer}
                                    //autoStart= {step === 2 && countDowning}
                                />}
                  
                </div>
              </CustomAntdForm>
              
              
              
            </div>
          </Card>
        </div>
    </React.Fragment>
  );
};

export default Step5;
