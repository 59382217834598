import { Col, Form, Select } from "antd";
import React, { useState } from "react";
import SelectWithFilter from "../select-with-filter-component";

const ComboField = ({
  onCodeSelected: onOptionSelected,
  label,
  name,
  placeholder,
  options,
  showSearch,
  required = false,
  disable = false,
  size = "large",
  height,
}) => {
  const { Option } = Select;

  const handleCodeSelected = (value) => {
    if (onOptionSelected) {
      onOptionSelected(value);
    }
  };

  return (
    <React.Fragment>
      <Form.Item
        style={{ width: "100%" }}
        label={label ?? ""}
        name={name}
        rules={[{ required: required, message: "Champ obligatoire" }]}
        tooltip={{
          title: required ? 'Champs obligatoire' : "",
          icon: <span style={{color: 'red'}}>{required ? '*' : ''}</span>,
        }}
      >
        <SelectWithFilter
          showSearch={showSearch ?? true}
          placeholder={placeholder ?? ""}
          size={size}
          style={{ height: height }}
          //disabled={disable}
          // suffixIcon={state.isCodeLoading ? (<Space><div>Chargement...</div><Spin size='small' /></Space>) : null}
          onChange={handleCodeSelected}
          disable={true}
        >
          {options.map((option, index) => (
            <Option key={index} value={option?.value}>
              {option?.name}
            </Option>
          ))}
        </SelectWithFilter>
      </Form.Item>
    </React.Fragment>
  );
};

export default ComboField;
