import React from "react";
import "./stepEchec.scss";
import errorIcon from "../../../assets/icones/error-icones.png";
import Btn from "../../components/Btn";

const Step0 = ({ title, message, goToPrevious }) => {
  
  return (
    <React.Fragment>
      <div className="step-error-card" >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 100,
              height: 150,
              width: 150,
            }}
          >
            <div>
              <img src={errorIcon} alt="" height={100} />
            </div>
          </div>
          <div className="step-error-info">
            <span>{title} </span>
          </div>
          <div>
            <p className="step-error-message">
              {message}.{" "}
            </p>
          </div>

          <div style={{ marginTop: 25, marginBottom: 25, width: "100%" }}>
            <Btn title={"Retour"} onClick={goToPrevious} />
          </div>
          <div className="step-error-contact">
            <span>
              Besoin d'aide ? <strong>contactez-nous</strong>
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Step0;
