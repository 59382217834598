import { Outlet, Link } from "react-router-dom";
import { Layout } from "..";
import Header from "./header/Header";
import MobileMenu from "./mobile-menu.page";
import Footer from "./footer/Footer";

function MainLayout() {
  return (
    <Layout>
      <Header />
      <Outlet />
      <MobileMenu />
      <Footer />
      {/*<footer className="footer-bottom" ><center>@Copyright LONACI 2023</center></footer>*/}
    </Layout>
  );
}

export default MainLayout;
