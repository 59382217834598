import React, { useState } from "react";
import { Alert, Button, Card, Form } from "antd";
import "./style.scss";
import { InfoCircleOutlined } from "@ant-design/icons";
import PatternFormatNumberField from "./PatternFormatNumberField";
import CustomAntdForm from "../../components/forms/custom-antd-form";
import { ProductService } from "../../../common/services";
import _ from "lodash";
import Btn from "../../components/Btn";
import SimpleInputField from "../../components/forms/fields/SimpleInputField";

const Step4 = ({ title, data, sCallback, eCallback }) => {
  console.log("datanumero :::", data);
  const [form] = Form.useForm();
  const [alertErrors, setAlertErrors] = useState(null);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const onValidate = () => {
    console.log("dataStep :::", data);
    // onSubmit(data);
    
    form.validateFields().then((values) => {
      setIsSubmiting(true);
      const phoneNumber = _.replace(
        values?.phoneNumber,
        new RegExp("[\\s-]", "g"),
        ""
      );
      console.log("phone :::", phoneNumber);
      
      const generateOTPRequest = {
        productCode: data?.initForm?.productCode,
        phoneNumber: phoneNumber,
        ticketNumber: data?.ticketInfo?.ticketNumber
      };

      console.log("generateOTP :::", generateOTPRequest);
      ProductService.generateOTP(generateOTPRequest)
        .then((response) => {
          console.log("response :::", response);
          if (response?.success) {
            if (sCallback) {
              sCallback(data, {
                phoneNumber: values?.phoneNumber,
                otp: response?.otp,
                firstname: values?.firstname ?? null,
                lastname: values?.lastname ?? null
              });
            }
          } else {
            setAlertErrors(response?.message);
          }
        })
        .catch((error) => {
          console.log(error);
            setAlertErrors(
              "Une erreur est survenue pendant le traitement de votre requête, veuillez réessayer"
            );
        })
        .finally(()=> setIsSubmiting(false));
    });
  };

  return (
    <React.Fragment>
      <div className="step-mobile-number-block">
        <Card className="step-phone-card">
          <div className= {`${data?.initForm?.productCode === 'PR07' ? 'step-phone-section_' : ""} step-phone-section`}>
            <p className="step-phone-card-title roboto-medium">{title}</p>
            <CustomAntdForm form={form} variant="filled" id={data?.initForm?.productCode === 'PR07' ? "step4_" : "step4"}>
              
              <PatternFormatNumberField
                label={"Numéro de téléphone"}
                name={"phoneNumber"}
                placeholder=""
                format="##-##-##-##-##"
                className="form-control"
                required={true}
              />
              { data?.initForm?.productCode === 'PR07' && (<SimpleInputField
                name={"firstname"}
                label={"Nom"}
                placeholder={"Saisir votre nom"}
                iProps={{ style: { width: "100%" } }}
                size="medium"
                required={true}
                isCapitalise={true}
              />)}
              
              {data?.initForm?.productCode === 'PR07' && (<SimpleInputField
                name={"lastname"}
                label={"Prenoms"}
                placeholder={"Saisir votre pénoms"}
                iProps={{ style: { width: "100%" } }}
                size="medium"
                required={true}
                isCapitalise={true}
              />)}

            </CustomAntdForm>
            <div className="infos">
              <div className="code-de-confirmation">
                <InfoCircleOutlined style={{ opacity: "50%", paddingTop: 8 }} />
                <span
                  style={{
                    fontSize: "18px",
                    color: "#0000004A"
                  }}
                >
                  Vous recevrez un code de confirmation par message
                </span>
              </div>
              <div className="code-de-confirmation">
                <InfoCircleOutlined  style={{ opacity: "50%", paddingTop: 8 }}/>
                <div className="infoSaisie">
                  <span
                    className="step-phone-bold-label"
                  >
                    Veuillez noter que le numéro de paiement saisi ne pourra plus
                    être modifié ultérieurement.
                  </span>
                  <br />
                  <span
                    style={{
                      fontSize: "18px",
                      color: "#0000004A",
                    }}
                  >
                    Veuillez donc vous assurer de son exactitude.
                  </span>
                </div>
              </div>
            </div>
            {alertErrors && (
                <div className="animated" style={{marginBottom: 10}}>
                  <Alert message={`${alertErrors}`} type="error" showIcon />
                </div>
              )}

            <Btn title={"Continuer"} loading={isSubmiting} onClick={onValidate}/>
            { data?.initForm?.productCode !== 'PR07' && (<div className="mb-35"></div>)}
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Step4;
