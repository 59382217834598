import React from "react";
import "./stepPrixTicket.scss";
import joy from "../../../assets/Group.png";
import { Button, Card, Form } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import numberHelper from "../../../common/helper/number.helper";
import Btn from "../../components/Btn";

const Step2 = ({ title, onSubmit, data }) => {
  return (
    <React.Fragment>
      <Card className="step-winner-card" style={{marginBottom:0, marginTop:20}}>
        <div className="container-img">
          <img className="img" src={joy} alt="" />
          <div className="step-image-text">
            <span>{title}</span>
            <br />
            <span>Votre ticket est gagnant!</span>
          </div>
        </div>
        <div className="container-gain">
          <div className="gain">
            <p className="mtn-text">Votre gain s'élève à</p>
            <div className="step-winner-amount">
              <label className="mtn">
                {numberHelper.formatWithThousandsSeparator(
                  parseInt(data?.ticketInfo?.gain ?? 0)
                )}
              </label>
              <label className="fcfa">F cfa</label>
            </div>
            <div className="infoCircle">
              <InfoCircleOutlined />
              <p style={{width: "100%"}}>
                Cliquez sur continuer pour encaisser votre gain
              </p>
            </div>
          </div>
          <Btn title={"Continuer"} onClick={() => (onSubmit ? onSubmit() : null)} />
          
        </div>
      </Card>
    </React.Fragment>
  );
};

export default Step2;
