import { useMemo } from "react";

import "./App.scss";

import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";

import { AppContext } from "./common/constants";

import { Home, MainLayout } from "./pages";
import Resultat from "./pages/resultat";
import PaiementTicket from "./pages/paiement-ticket/paiement.page";

function App() {
  const routers = useMemo(
    () =>
      createBrowserRouter([
        {
          path: "",
          element: <MainLayout />,
          errorElement: <div>Not found</div>,
          children: [
            {
              path: "/",
              element: <Home />,
            },
            {
              path: "/paiement-ticket",
              element: <PaiementTicket />,
            },
            // {
            //   path: "/resultats",
            //   element: <Resultat />,
            //   loader : async () =>  {
            //     window.open('https://www.lonaci.ci/', '_blank');
            //     return redirect("/")

            //   }
            // },
          ],
        },
      ]),
    []
  );

  return (
    <div className="App">
      <AppContext.Provider>
        <RouterProvider router={routers} />
      </AppContext.Provider>
    </div>
  );
}

export default App;
