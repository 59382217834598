export default {
  formatWithThousandsSeparator : (number) => {
      if (typeof number === 'number') {
        return number.toLocaleString();
      }
      return 0;
    },

    formatAmount: (value)=> {
      // Supprimer les caractères non numériques
      const numericValue = value.replace(/\D/g, '');
      
      // Formater le nombre en ajoutant des séparateurs de milliers
      const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      
      return formattedValue;
    },

    formatPhoneNumber: (value)=> {
      // Supprimer les caractères non numériques
      const numericValue = value.replace(/\D/g, '');
      
      // Formater le numéro de téléphone
      const  formattedValue = '';
      
      if (numericValue.length > 2) {
        formattedValue += `(${numericValue.slice(0, 2)}) `;
      }
      if (numericValue.length > 6) {
        formattedValue += `${numericValue.slice(2, 6)} `;
      }
      if (numericValue.length > 10) {
        formattedValue += `${numericValue.slice(6, 10)} `;
      }
      if (numericValue.length > 10) {
        formattedValue += `${numericValue.slice(10)}`;
      }
      
      return formattedValue.trim();
    }
    
}

  