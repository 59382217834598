import { Col, Form } from "antd";
import React from "react";
import { NumericFormat } from "react-number-format";

const NumberFormatField = ({
  label,
  placeholder,
  name,
  required = false,
  prefix,
  suffix,
  requiredMsg,
  disable,
}) => {
  return (
    <React.Fragment>
      <Form.Item
        style={{ width: "100%" }}
        label={label}
        name={name}
        rules={[
          { required: required, message: requiredMsg ?? "Champ obligatoire" },
        ]}
        tooltip={{
          title: required ? 'Champs obligatoire' : "",
          icon: <span style={{color: 'red'}}>{required ? '*' : ''}</span>,
        }}
      >
        <NumericFormat
          placeholder={placeholder}
          thousandSeparator=" "
          prefix={prefix}
          suffix={suffix}
          style={{ width: "99%", height: "30px" }}
          className="ant-input ant-input-lg css-dev-only-do-not-override-1qhpsh8 ant-input-outlined"
          disabled={disable}
        />
      </Form.Item>
    </React.Fragment>
  );
};

export default NumberFormatField;
