import footerLogo from "../../../assets/LOGO-PAYME-(LONACI)-V3-Orange.png";
import "./footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-logo">
        <img className="img-footer" src={footerLogo} alt="" />
        <div className="footer-text">
          <span className="text-footer">
            Siège Social: Bvd de Marseille Zone 4, Rue du Chevalier de Clieu
          </span>
          <span className="text-footer"> Abidjan, Marcory</span>
        </div>

        <div className="footer-text-mobile">
          <span className="text-footer">
            Siège Social: Bvd de Marseille Zone 4, Rue du Chevalier de Clieu Abidjan, Marcory
          </span>
        </div>

      </div>
      <div className="footer-copyright">
        <hr style={{ backgroundColor: "#151414" }} />
        <span>
          &copy; Copyright 2024 | LONACI Powered By Direction des Systèmes de
          l'Information - DSI | All Rights Reserved
        </span>
      </div>
    </footer>
  );
};

export default Footer;
