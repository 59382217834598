import { exeRequest } from "../utils/APIUtils";

export default {
  getAll: async () => await exeRequest("/api/products", "GET"),

  verifyTicket: async (data) =>
    await exeRequest("/api/tickets/checking", "POST", data),

  generateOTP: async (data) =>
    await exeRequest("/api/otp/generate", "POST", data),

  verifyOTP: async (data) =>
    await exeRequest("/api/otp/validate", "POST", data),

  payTicket: async (data) => await exeRequest("/api/tickets/pay", "POST", data),

  getAllTicketGrattage: async () => await exeRequest("/api/products/grattage", "GET"),
};
