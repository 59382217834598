import { Button } from "antd";
import React from "react";
import './btn-style.scss';

const Btn = ({ title, onClick, loading, height = 48, width = "100%" }) => {
  return (
    <React.Fragment>
      <Button
        key="console"
        size="large"
        style={{
          height: height,
          width: width
        }}
        className="btn-span"
        onClick={onClick ? onClick : () => console.log("")}
        loading={loading}
      >
        {title}
      </Button>
    </React.Fragment>
  );
};

export default Btn;
