import React from 'react'

import { Form } from 'antd'

export default function CustomAntdForm(props) {
    return (
        <>
            <Form
                name="basic"
                {...layout}
                autoComplete="off"
                {...props}
            />
        </>
    );
}

const layout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 24 },
    }
};