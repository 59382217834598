import React from "react";
import checkIcon from "../../../assets/icones/check.png";
import Btn from "../../components/Btn";
import { Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import numberHelper from "../../../common/helper/number.helper";
import './step-success-style.scss';

const StepSuccess=({title, data})=> {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <div className="step-success-card">
                <div className="step-success-card-item">
                    <div
                        style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 100,
                        height: 150,
                        width: 150,
                        }}
                    >
                        <div>
                        <img src={checkIcon} alt="" height={150} />
                        </div>
                    </div>
                    <div className="details-title">
                        <span>Transaction effectuée avec succès ! </span>
                    </div>
                    <div style={{display:'flex', justifyContent: 'space-between', flexDirection: 'column', width: '100%'}}>
                        <div className="details-item" >
                            <span >Réference
                            </span>
                            <Typography.Title level={5} style={{margin:0}}> {data?.ticketInfo?.transactionId} </Typography.Title>
                        </div>

                        <div className="details-item" >
                            <span >Numéro de paiement
                            </span>
                            <Typography.Title level={5} style={{margin:0}}> {data?.ticketInfo?.phoneNumber} </Typography.Title>
                        </div>

                        <div className="details-item" >
                            <span >Montant payé
                            </span>
                            <Typography.Title level={5} style={{margin:0}}> {numberHelper.formatWithThousandsSeparator(parseInt(data?.ticketInfo?.gain ?? 0))} F CFA </Typography.Title>
                        </div>
                    </div>

                    <div style={{ marginTop: 25, marginBottom: 25, width: "100%" }}>
                        <Btn title={"Terminer"} onClick={()=> navigate("/")}  />
                    </div>
                    <div className="tk-contact-us">
                        <span>
                        Besoin d'aide ? <strong>contactez-nous</strong>
                        </span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default StepSuccess;